import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Button, Flex, Layout, Menu, MenuProps } from 'antd'

import { MENU_LIST } from '@/constants'
import { useLeftDrawer, useNavigation } from '@/hooks'

const { Sider } = Layout

export const LeftDrawer = () => {
  const { t, i18n } = useTranslation()
  const navigation = useNavigation()
  const { isHidden, toggle } = useLeftDrawer()

  const menuList = useMemo(
    () =>
      MENU_LIST.map((item) => ({
        ...item,
        label: t(item.label, ''),
      })),
    [MENU_LIST, i18n.language],
  )

  const onMenuItemClick: MenuProps['onClick'] = (e) => {
    const path = e.keyPath.reverse().join('/')
    navigation.goTo(path)
  }

  return (
    <Sider
      trigger={
        <Flex justify="center" className="bg-primary" onClick={toggle}>
          <Button
            type="text"
            className="h-16 w-16 hover:bg-primary"
            block={true}
            icon={isHidden ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          />
        </Flex>
      }
      collapsible
      collapsed={isHidden}
    >
      <Menu
        mode="inline"
        className="h-screen border-e-0 bg-primary"
        defaultSelectedKeys={['/labs']}
        items={menuList}
        onClick={onMenuItemClick}
      />
    </Sider>
  )
}
