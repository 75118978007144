export const theme = {
  token: {
    colorPrimary: '#005795',
    colorBgContainer: '#FAFAFAFA',
  },
  components: {
    Button: {
      // colorPrimary: '#000',
      // colorPrimaryHover: '#000',
    },
    Input: {
      colorBgContainerDisabled: '#f5f5f5',
      colorPrimaryHover: '#f5f5f5',
    },
    Menu: {
      colorItemBgSelected: '#edf6ff',
    },
  },
}
