import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Input, Tabs } from 'antd'

import FAKE_DATA from '@/mock/deviceRecords.json'

import { DeviceRecordsTable } from '../organisms'

interface TabBarExtraContentProps {
  searchStr: string
  setSearchStr: (value: string) => void
}

const TabBarExtraContent = ({
  searchStr,
  setSearchStr,
}: TabBarExtraContentProps) => {
  const { t } = useTranslation()
  return (
    <Flex justify="space-between" align="center" gap={16}>
      <Button type="primary" shape="round">
        {t('Export')}
      </Button>
      <Flex gap={16}>
        <Input.Search
          placeholder="Search"
          value={searchStr}
          onChange={(e) => setSearchStr(e.target.value)}
        />
      </Flex>
    </Flex>
  )
}

export const DeviceRecordsPage = () => {
  const { t } = useTranslation()
  const [searchStr, setSearchStr] = useState('')

  const filteredData = useMemo(() => {
    return FAKE_DATA.filter((item) => item.name.includes(searchStr))
  }, [searchStr, FAKE_DATA])

  const items = [
    {
      label: t('Records.All'),
      key: 'all',
      children: <DeviceRecordsTable data={filteredData} />,
    },
    {
      label: t('Records.Parameter'),
      key: 'parameter',
      children: <DeviceRecordsTable data={filteredData} />,
    },
    {
      label: t('Records.Warning'),
      key: 'warning',
      children: <DeviceRecordsTable data={filteredData} />,
    },
  ]

  return (
    <Flex
      vertical
      gap={16}
      className="scrollbar-hide h-full overflow-y-auto px-8"
    >
      {/* header */}
      <Flex vertical gap={10}>
        <Flex vertical>
          <span className="text-[#b4b5b0]">EC111A</span>
          <Flex align="center" gap={16}>
            <p className="text-xl font-bold">功能性高分子實驗室</p>
          </Flex>
        </Flex>
      </Flex>
      {/* table */}
      <Flex flex={1} vertical>
        {/* title */}
        <p className="text-lg font-bold text-blue-950">{t('DeviceRecords')}</p>
        {/* tabs / export button / search */}
        <Tabs
          tabBarExtraContent={
            <TabBarExtraContent
              searchStr={searchStr}
              setSearchStr={setSearchStr}
            />
          }
          items={items}
        />
      </Flex>
    </Flex>
  )
}
