import { useTranslation } from 'react-i18next'
import { EditOutlined } from '@ant-design/icons'
import type { TableProps } from 'antd'
import { Space, Table } from 'antd'

interface DeviceRecordsTableProps {
  data: DataType[]
}

interface DataType {
  id: number
  name: string
  type: string
  location: string
  errorCode: string
}

export const DeviceRecordsTable = ({ data }: DeviceRecordsTableProps) => {
  const { t } = useTranslation()
  const columns: TableProps<DataType>['columns'] = [
    {
      title: t('Records.Name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('Records.Type'),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: t('Records.Location'),
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: t('Records.ErrorCode'),
      dataIndex: 'errorCode',
      key: 'errorCode',
    },
    {
      title: t('Action'),
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined className="cursor-pointer" />
        </Space>
      ),
    },
  ]

  return <Table<DataType> columns={columns} dataSource={data} />
}
