import {
  Link,
  NavigateOptions,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

export const useNavigation = () => {
  const navigate = useNavigate()
  const params = useParams()

  const location = useLocation()

  const currentPath = location.pathname

  const handleGoBack = () => {
    navigate(-1)
  }
  const handleGoTo = (path: string, options?: NavigateOptions) => {
    const formattedPath = path.charAt(0) === '/' ? path : `/${path}`
    navigate(formattedPath, options)
  }
  const handlePush = (path: string) => {
    navigate(`${currentPath}/${path}`)
  }
  const handleGoNext = () => {
    navigate(1)
  }
  const handleGoHome = () => {
    navigate('/')
  }

  return {
    navigate,
    goBack: handleGoBack,
    goNext: handleGoNext,
    goTo: handleGoTo,
    push: handlePush,
    goHome: handleGoHome,
    params,
    Link,
    currentPath,
  }
}
