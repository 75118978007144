import { Navigate, useLocation } from 'react-router-dom'

import { useAuth } from '@/hooks'

const RedirectRoute = () => {
  const { isAuthenticated } = useAuth()
  const location = useLocation()

  // if (isAuthenticated) {
  return <Navigate to="/labs" />
  // } else {
  //   return <Navigate to="/login" state={{ prevPage: location.pathname }} />
  // }
}
RedirectRoute.layouts = 'default'
RedirectRoute.displayName = 'RedirectRoute'
export default RedirectRoute
