import { useTranslation } from 'react-i18next'
import { EditOutlined } from '@ant-design/icons'
import type { TableProps } from 'antd'
import { Space, Table } from 'antd'

interface LabListTableProps {
  data: DataType[]
}

interface DataType {
  id: number
  name: string
  type: string
  deviceCount: number
  connectCount: number
  importantDevice: string
}

export const LabListTable = ({ data }: LabListTableProps) => {
  const { t } = useTranslation()
  const columns: TableProps<DataType>['columns'] = [
    {
      title: t('LaboratoryList.Name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('LaboratoryList.DeviceCount'),
      dataIndex: 'deviceCount',
      key: 'deviceCount',
    },
    {
      title: t('LaboratoryList.ConnectCount'),
      dataIndex: 'connectCount',
      key: 'connectCount',
    },
    {
      title: t('LaboratoryList.Type'),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: t('LaboratoryList.ImportantDevice'),
      dataIndex: 'importantDevice',
      key: 'importantDevice',
    },
    {
      title: t('Action'),
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined className="cursor-pointer" />
        </Space>
      ),
    },
  ]

  return <Table<DataType> columns={columns} dataSource={data} />
}
