import { PropsWithChildren, useEffect } from 'react'

import { useAuth, useNavigation } from '@/hooks'

export const DefaultLayout = ({ children }: PropsWithChildren) => {
  const navigation = useNavigation()
  // const { isAuthenticated } = useAuth()

  // useEffect(() => {
  //   if (!isAuthenticated && navigation.currentPath !== '/login') {
  //     navigation.goTo('login', { state: { prevPage: navigation.currentPath } })
  //   }
  // }, [isAuthenticated])

  return (
    <div className="flex h-screen w-screen flex-col bg-primary">
      <div className="flex flex-1 flex-row items-center justify-center p-8">
        <DefaultBody>{children}</DefaultBody>
        <DefaultAside />
      </div>
    </div>
  )
}

const DefaultBody = ({ children }: PropsWithChildren) => {
  return <div className="h-full flex-1 rounded-l-md bg-primary">{children}</div>
}

const DefaultAside = () => {
  return <div className="h-full flex-1 rounded-r-md bg-primary">123</div>
}
