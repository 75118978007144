import { createTrackedSelector } from 'react-tracked'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface LeftSidebarState {
  isHidden: boolean
  hide: () => void
  show: () => void
  toggle: () => void
}

const LeftDrawerStore = create<LeftSidebarState>()(
  devtools(
    (set) => ({
      isHidden: true,
      hide: () => {
        set({ isHidden: true })
      },
      show: () => {
        set({ isHidden: false })
      },
      toggle: () => {
        set((state) => ({ isHidden: !state.isHidden }))
      },
    }),
    { name: 'Leftsidebar store', serialize: { options: true } },
  ),
)

/**
 * @description React custom hook which uses Zustand to manage the Left Drawer component.
 */
export const useLeftDrawer = createTrackedSelector(LeftDrawerStore)
