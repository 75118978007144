import { lazy, Suspense, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Spin, Switch } from 'antd'
import axios from 'axios'

import { SENSOR_API } from '@/constants'

import { DeviceVerticalList } from '../molecules'

const EmbeddedLabModel = lazy(() => import('../organisms/EmbeddedLabModel'))

const TYPES = ['running', 'abnormal', 'spectral', 'layer']
const BLOCK_LIST: BlockType[] = [
  'general',
  'organic',
  'inorganic',
  'special',
  'instrumental',
]

export const HomePage = () => {
  const { t } = useTranslation()
  const [active, setActive] = useState('running')
  const [modelVisible, setModelVisible] = useState(false)
  const [typedData, setTypedData] = useState<BlockData>({} as BlockData)

  useEffect(() => {
    if (!SENSOR_API) return
    axios
      .get(SENSOR_API)
      .then(({ data }) => {
        setTypedData({
          general: Object.keys(data).map((key) => ({
            ...data[key],
            name: key,
            connect: true,
          })),
          organic: [],
          inorganic: [],
          special: [],
          instrumental: [],
        })
      })
      .catch(console.error)
  }, [SENSOR_API])

  return (
    <Flex vertical gap={16} className="scrollbar-hide h-full overflow-y-auto">
      {/* header */}
      <Flex justify="space-between">
        {/* left */}
        <Flex vertical gap={10} className="px-8">
          <Flex vertical>
            <span className="text-[#b4b5b0]">EC111A</span>
            <Flex align="center" gap={16}>
              <p className="text-xl font-bold">功能性高分子實驗室</p>
              <Flex gap={6}>
                <span>3D Lab model</span>
                <Switch value={modelVisible} onChange={setModelVisible} />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        {/* right */}
        <Flex className="mr-6" gap={10}>
          {TYPES.map((type) => (
            <Button
              type={active === type ? 'primary' : 'default'}
              shape="round"
              key={type}
              onClick={() => setActive(type)}
            >
              {t(`Status.${type}`, type)}(1)
            </Button>
          ))}
        </Flex>
      </Flex>
      {/* list */}
      <Flex flex={1}>
        {modelVisible ? (
          <Suspense fallback={<Spin size="large" />}>
            <EmbeddedLabModel />
          </Suspense>
        ) : (
          BLOCK_LIST.map((block: BlockType) => (
            <DeviceVerticalList
              key={block}
              data={typedData[block] || []}
              title={t(`Home.${block}`, block)}
            />
          ))
        )}
      </Flex>
    </Flex>
  )
}
