import { useMemo } from 'react'
import {
  GetUserRequestParams,
  GetUserResponse,
  PostUserCreateRequestBody,
  PostUserCreateResponse,
  PostUserLoginRequestBody,
  PostUserLoginResponse,
} from '@brilltek42/template-types'
import {
  MutationFunction,
  QueryFunction,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import axios from 'axios'

import { API } from '@/utils/API'

import { useAPI } from './useAPI'
import { useNavigation } from './useNavigation'
import { useToken } from './useToken'

export const useAuth = ({
  fallbackTo,
  redirectTo,
}: {
  /** @description  Redirect to the route if authenticated. */
  fallbackTo?: string
  /** @description  Fallback to the route if **NOT** authenticated. */
  redirectTo?: string
} = {}) => {
  const { request } = useAPI()

  const navigation = useNavigation()
  const tokenStore = useToken()

  /**
   * @description API `POST` request _(with credentials)_ to obtain the **JWT token**.
   */
  const getToken: MutationFunction<
    PostUserLoginResponse,
    PostUserLoginRequestBody
  > = async (credentials) => {
    const res = await request<PostUserLoginResponse, PostUserLoginRequestBody>(
      'post',
      API.routes.user.login,
      { data: credentials },
    )
    return res.data
  }

  const tokenMutation = useMutation({
    mutationFn: getToken,
    // useErrorBoundary: (error) => error.response?.status !== 400,
    // onError: (error) => {
    //   throw error.response?.data?.errors
    // },
  })
  const login = async (credentials: PostUserLoginRequestBody) => {
    const tokenRes = await tokenMutation.mutateAsync(credentials)
    console.log('token', tokenRes)

    if (tokenMutation.isError) {
      if (axios.isAxiosError(tokenMutation.error)) {
        throw tokenMutation.error.response?.data.errors
      } else {
        throw tokenMutation.error
      }
    } else {
      tokenStore.updateToken(tokenRes.token)
      tokenStore.updateUserId(tokenRes.id)

      // if (!credentials.rememberMe) {
      //   tokenStore.cancelTokenStorage()
      // }
      return tokenRes.token
    }
  }

  const logout = () => {
    tokenStore.clear()
  }

  const postUserCreate: MutationFunction<
    PostUserCreateResponse,
    PostUserCreateRequestBody
  > = async (newUserData) => {
    const { data } = await request<PostUserCreateResponse>(
      'post',
      API.routes.user.register,
      {
        data: newUserData,
      },
    )
    return data
  }

  const register = useMutation({ mutationFn: postUserCreate })

  // const isAuthenticated = useMemo(
  //   () => Boolean(tokenStore.token) && Boolean(tokenStore.userId),
  //   [tokenStore.token, tokenStore.userId],
  // )
  const isAuthenticated = true

  const getUserData: QueryFunction<GetUserResponse> = async () => {
    const { data } = await request<
      GetUserResponse,
      never,
      GetUserRequestParams
    >('get', API.routes.user.data(tokenStore.userId), {
      params: {
        id: tokenStore.userId?.toString,
      } as unknown as GetUserRequestParams,
    })

    return data
  }
  const userDataQuery = useQuery({
    queryKey: ['user', tokenStore.userId],
    queryFn: getUserData,
    enabled: false, //isAuthenticated,
  })

  return {
    user: userDataQuery,
    login,
    logout,
    register,
    isAuthenticated,
  }
}
