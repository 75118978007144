import { PropsWithChildren, useEffect } from 'react'
import { AiOutlineBell, AiOutlineMail } from 'react-icons/ai'
import { Avatar, Layout } from 'antd'

import logo from '@/assets/img/logo.png'
import userImg from '@/assets/img/user.png'
import { useAuth, useNavigation } from '@/hooks'

import { LeftDrawer } from '../templates'
const { Header, Content } = Layout

export const ContentLayout = ({ children }: PropsWithChildren) => {
  const navigation = useNavigation()
  // const { isAuthenticated } = useAuth()

  // useEffect(() => {
  //   if (!isAuthenticated && navigation.currentPath !== '/login') {
  //     navigation.goTo('login', { state: { prevPage: navigation.currentPath } })
  //   }
  // }, [isAuthenticated])

  return (
    <Layout className="h-screen">
      <LeftDrawer />
      <Layout>
        <Header className="flex flex-row items-center justify-between bg-primary px-4">
          <div className="flex">
            <img src={logo} alt="logo" className="h-12" />
          </div>
          <div className="flex flex-row items-center gap-4">
            <AiOutlineMail size={24} className="cursor-pointer" />
            <AiOutlineBell size={24} className="cursor-pointer" />
            <Avatar size="large" src={userImg} className="cursor-pointer" />
          </div>
          {/* <Button type="primary" title={t('Logout')} onClick={logout}>
            {t('Logout')}
          </Button> */}
        </Header>
        <Content className="bg-primary p-2">{children}</Content>
      </Layout>
    </Layout>
  )
}
