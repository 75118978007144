import { useTranslation } from 'react-i18next'
import { CiWifiOff, CiWifiOn } from 'react-icons/ci'
import { Flex, Image, List } from 'antd'

interface StatusCardProps {
  data: MachineDataProps
}

const STATUS_COLOR = {
  1: '#2de479',
  2: '#858a96',
  3: '#efa83a',
}

const CARD_COLOR: Record<string, string> = {
  Fumehood: '#e3e5de',
  Reactions: '#ffffff',
  Substrate: '#ebd42b',
  None: 'transparent',
}

export const DeviceCard = ({ data }: StatusCardProps) => {
  const { t } = useTranslation()
  return (
    <List.Item key={data.id} style={{ paddingTop: 8, paddingBottom: 4 }}>
      <Flex
        className="rounded-2xl p-4"
        vertical={true}
        style={{
          backgroundColor: CARD_COLOR[data.type ?? 'Fumehood'],
          borderWidth: data.type === 'None' ? 1 : 0,
          borderStyle: 'solid',
          borderColor: '#cdcdc6',
          minHeight: 180,
        }}
      >
        {/* name, status, connected */}
        <Flex align="center" gap={4}>
          {data.connect ? (
            <CiWifiOn size={28} color="#278edf" />
          ) : (
            <CiWifiOff size={28} color="#df353b" />
          )}
          <p className="text-lg font-bold text-[#45454b]">{data.name}</p>
          <div
            className="ml-auto h-4 w-4 rounded-full"
            style={{ backgroundColor: STATUS_COLOR[data.status ?? 1] }}
          />
        </Flex>
        {/* description */}
        <Flex>
          <span
            className="text-xs font-bold"
            style={{ color: data.type === 'Substrate' ? 'white' : '#b4b9bb' }}
          >
            {data.description}
          </span>
        </Flex>
        {/* humidity, temperature, remarks, image */}
        {data.type !== 'None' && (
          <Flex justify="space-between" align="flex-end" className="mt-4">
            <Flex vertical>
              {data.remarks && (
                <div className="rounded-full bg-[#f0f9fb] px-[5px]">
                  <span className="text-[#1d7395]">{data.remarks}</span>
                </div>
              )}
              <Flex gap={4} className="mt-2">
                {data.humidity! > 0 && (
                  <div className="rounded-full bg-[#f6cf8a] px-[5px]">
                    <span className="text-[#996920]">
                      {t('Humidity')}
                      {data.humidity?.toPrecision(3)}%
                    </span>
                  </div>
                )}
                {data.temperature! > 0 && (
                  <div className="rounded-full bg-[#fef8ec] px-[5px]">
                    <span className="text-[#9e722d]">
                      {t('Temperature')}
                      {data.temperature?.toPrecision(3)}°C
                    </span>
                  </div>
                )}
              </Flex>
            </Flex>
            <Image
              src={data.image ?? 'https://placehold.co/80x100'}
              preview={false}
            />
          </Flex>
        )}
      </Flex>
    </List.Item>
  )
}
