import {
  AppstoreOutlined,
  DashboardOutlined,
  FileTextOutlined,
} from '@ant-design/icons'

export const MENU_LIST = [
  {
    key: '/labs',
    label: 'LabList',
    icon: <AppstoreOutlined />,
  },
  {
    key: '/lab/overview',
    label: 'Overview',
    icon: <DashboardOutlined />,
  },
  {
    key: '/lab/device/records',
    label: 'DeviceRecords',
    icon: <FileTextOutlined />,
  },
]
