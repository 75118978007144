import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Input, Tabs } from 'antd'

import FAKE_DATA from '@/mock/labList.json'

import { LabListTable } from '../organisms'

interface TabBarExtraContentProps {
  searchStr: string
  setSearchStr: (value: string) => void
}

const TabBarExtraContent = ({
  searchStr,
  setSearchStr,
}: TabBarExtraContentProps) => {
  const { t } = useTranslation()
  return (
    <Flex justify="space-between" align="center" gap={16}>
      <Button type="primary" shape="round">
        {t('Add', { name: t('Lab') })}
      </Button>
      <Flex gap={16}>
        <Input.Search
          placeholder="Search"
          value={searchStr}
          onChange={(e) => setSearchStr(e.target.value)}
        />
      </Flex>
    </Flex>
  )
}

export const LabListPage = () => {
  const { t } = useTranslation()
  const [searchStr, setSearchStr] = useState('')

  const filteredData = useMemo(() => {
    return FAKE_DATA.filter((item) => item.name.includes(searchStr))
  }, [searchStr, FAKE_DATA])

  const items = [
    {
      label: t('LaboratoryList.All'),
      key: 'all',
      children: <LabListTable data={filteredData} />,
    },
    {
      label: t('LaboratoryList.Featured'),
      key: 'featured',
      children: (
        <LabListTable
          data={filteredData.filter((item) => item.type === '特色')}
        />
      ),
    },
    {
      label: t('LaboratoryList.Teaching'),
      key: 'teaching',
      children: (
        <LabListTable
          data={filteredData.filter((item) => item.type === '教學')}
        />
      ),
    },
  ]

  return (
    <Flex
      vertical
      gap={16}
      className="scrollbar-hide h-full overflow-y-auto px-8"
    >
      {/* table */}
      <Flex flex={1} vertical>
        {/* title */}
        <p className="text-lg font-bold text-blue-950">{t('LabList')}</p>
        {/* tabs / export button / search */}
        <Tabs
          tabBarExtraContent={
            <TabBarExtraContent
              searchStr={searchStr}
              setSearchStr={setSearchStr}
            />
          }
          items={items}
        />
      </Flex>
    </Flex>
  )
}
