const API_BASE_URL = import.meta.env.VITE_API_URL

export const API = {
  baseURL: API_BASE_URL,

  routes: {
    user: {
      data: (param?: string | number) => `/user/${param ?? ''}`,
      update: (param?: string | number) => `/user/${param ?? ''}`,
      delete: (param?: string | number) => `/user/${param ?? ''}`,
      list: '/users',
      login: '/login',
      register: '/users',
    },
  },
  intervals: {},
  requestTimeout: {},
  retries: {},
  staleTimes: {},
}
