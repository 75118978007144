import { createTrackedSelector } from 'react-tracked'
import { PostUserLoginResponse } from '@brilltek42/template-types'
import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'
/**
 * @description JWT authentication token.
 * @link https://jwt.io/
 */

export type UserId = PostUserLoginResponse['id']

interface TokenState {
  token: string
  userId: UserId
  clear: () => void
  updateToken: (token: PostUserLoginResponse['token']) => void
  updateUserId: (userId: UserId) => void
  cancelTokenStorage: () => void
}

const tokenStore = create<TokenState>()(
  persist(
    devtools(
      (set) => ({
        token: '',
        userId: 0,
        clear: () => {
          set({ token: '', userId: 0 })
        },
        updateToken: (token) => {
          set({ token })
        },
        updateUserId: (userId) => {
          set({ userId })
        },
        // used when not to persist auth sesstion
        cancelTokenStorage: () => {
          window.localStorage.removeItem('token_storage')
        },
      }),
      { name: 'Token store' },
    ),
    {
      name: 'token_storage',
      storage: createJSONStorage(() => window.localStorage),
    },
  ),
)

/**
 * @description React custom hook which uses Zustand and to manage the API JWT token.
 */
export const useToken = createTrackedSelector(tokenStore)
